@font-face {
  font-family: HKGrotesk;
  src: url(hkgrotesk_bold.woff);
  font-weight: 700;
}

@font-face {
  font-family: HKGrotesk;
  src: url(hkgrotesk_regular.woff);
  font-weight: 400;
}

@font-face {
  font-family: TiemposHeadline;
  src: url(TiemposHeadline-Bold.woff);
  font-weight: 700;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  min-height: 100vh;
  line-height: 1.5;
}

p {
  font-family: "HKGrotesk", Roboto, sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;
}

h1 {
  font-family: "TiemposHeadline", serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 42px;
}

h2 {
  font-family: "TiemposHeadline", serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 34px;
}

h3 {
  font-family: "HKGrotesk", Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.4;
  font-size: 28px;
}

h4 {
  font-family: "HKGrotesk", Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.4;
  font-size: 18px;
}

h5 {
  font-family: "HKGrotesk", Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.4;
  font-size: 18px;
}

a {
  text-decoration: none;
  cursor: pointer;
  font-family: "HKGrotesk", Roboto, sans-serif;
  font-weight: 700;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

a.fancy {
  font-family: "HKGrotesk", Roboto, sans-serif;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}

a.fancy:hover {
  font-family: "HKGrotesk", Roboto, sans-serif;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}

a.fancy::after {
  content: "";
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: -4px;
  left: 0;
  transition: all 0.3s ease-out;
}

a.fancy:hover::after {
  width: 100%;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 20px;
  }
}

/* tablets/desktops and up ----------- */
@media (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 64px;
  }

  h2 {
    font-size: 44px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }
}

/* large desktops and up ----------- */
@media screen and (min-width: 1200px) {
  h1 {
    font-size: 64px;
  }

  h2 {
    font-size: 44px;
  }

  h3 {
    font-size: 36px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 24px;
  }
}

/* Overrides */
#fp-nav.fp-left {
  left: 12px !important;
  transition: all 0.4s ease-in-out;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: white !important;
  border: 1px solid black !important;
}
